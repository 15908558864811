@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: 85%;
  }

  body {
    @apply font-satoshi font-normal text-base text-body bg-whiten relative z-1;
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(160, 51, 255, 0.015) 0%,
        rgba(160, 51, 255, 0) 30%
      ),
      radial-gradient(
        50% 50% at 50% 50%,
        rgba(24, 119, 242, 0.02) 0%,
        rgba(24, 119, 242, 0) 40%
      ),
      radial-gradient(
        50% 50% at 50% 50%,
        rgba(255, 108, 92, 0.015) 0%,
        rgba(255, 108, 92, 0) 35%
      ),
      radial-gradient(
        50% 50% at 50% 50%,
        rgba(245, 206, 51, 0.01) 0%,
        rgba(245, 206, 51, 0) 45%
      ),
      radial-gradient(
        50% 50% at 50% 50%,
        rgba(37, 211, 102, 0.008) 0%,
        rgba(37, 211, 102, 0) 25%
      ),
      linear-gradient(180deg, #f5f7fa 0%, #eef1f5 100%); /* Added a light linear gradient for better contrast */
      background-color: #f7f9fc; /* Light cloudy base */
      background-size: cover;
      background-attachment: fixed;
  }
}

@layer components {
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chat-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
  .inbox-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
}

/* third-party libraries CSS */

.tableCheckbox:checked ~ div span {
  @apply opacity-100;
}
.tableCheckbox:checked ~ div {
  @apply bg-primary border-primary;
}

.apexcharts-legend-text {
  @apply !text-body dark:!text-bodydark;
}
.apexcharts-text {
  @apply !fill-body dark:!fill-bodydark;
}
.apexcharts-xcrosshairs {
  @apply !fill-stroke dark:!fill-strokedark;
}
.apexcharts-gridline {
  @apply !stroke-stroke dark:!stroke-strokedark;
}
.apexcharts-series.apexcharts-pie-series path {
  @apply dark:!stroke-transparent;
}
.apexcharts-legend-series {
  @apply !inline-flex gap-1.5;
}
.apexcharts-tooltip.apexcharts-theme-light {
  @apply dark:!bg-boxdark dark:!border-strokedark;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  @apply dark:!bg-meta-4 dark:!border-strokedark;
}
.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
  @apply dark:!bg-meta-4 dark:!border-meta-4 dark:!text-bodydark1;
}
.apexcharts-xaxistooltip-bottom:after {
  @apply dark:!border-b-meta-4;
}
.apexcharts-xaxistooltip-bottom:before {
  @apply dark:!border-b-meta-4;
}

.flatpickr-day.selected {
  @apply bg-primary border-primary hover:bg-primary hover:border-primary;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  @apply fill-primary;
}
.flatpickr-calendar.arrowTop:before {
  @apply dark:!border-b-boxdark;
}
.flatpickr-calendar.arrowTop:after {
  @apply dark:!border-b-boxdark;
}
.flatpickr-calendar {
  @apply dark:!bg-boxdark dark:!text-bodydark dark:!shadow-8 !p-6 2xsm:!w-auto;
}
.flatpickr-day {
  @apply dark:!text-bodydark;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  @apply !top-7 dark:!text-white dark:!fill-white;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  @apply !left-7;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  @apply !right-7;
}
span.flatpickr-weekday,
.flatpickr-months .flatpickr-month {
  @apply dark:!text-white dark:!fill-white;
}
.flatpickr-day.inRange {
  @apply dark:!bg-meta-4 dark:!border-meta-4 dark:!shadow-7;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.selected,
.flatpickr-day.endRange {
  @apply dark:!text-white;
}

.map-btn .jvm-zoom-btn {
  @apply flex items-center justify-center w-7.5 h-7.5 rounded border border-stroke dark:border-strokedark hover:border-primary dark:hover:border-primary bg-white hover:bg-primary text-body hover:text-white dark:text-bodydark dark:hover:text-white text-2xl leading-none px-0 pt-0 pb-0.5;
}
.mapOne .jvm-zoom-btn {
  @apply left-auto top-auto bottom-0;
}
.mapOne .jvm-zoom-btn.jvm-zoomin {
  @apply right-10;
}
.mapOne .jvm-zoom-btn.jvm-zoomout {
  @apply right-0;
}
.mapTwo .jvm-zoom-btn {
  @apply top-auto bottom-0;
}
.mapTwo .jvm-zoom-btn.jvm-zoomin {
  @apply left-0;
}
.mapTwo .jvm-zoom-btn.jvm-zoomout {
  @apply left-10;
}

.taskCheckbox:checked ~ .box span {
  @apply opacity-100;
}
.taskCheckbox:checked ~ p {
  @apply line-through;
}
.taskCheckbox:checked ~ .box {
  @apply bg-primary border-primary dark:border-primary;
}

.custom-input-date::-webkit-calendar-picker-indicator {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}

[x-cloak] {
  display: none !important;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  border-style: dotted;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
.cvssjs {
  width: 1em;
  height: 1em;
  display: inline-block;
  background: url('./assets/icons/cvssicons.png') 0 0 no-repeat;
  background-size: cover;
  font-size: 30px;
}

.AVN {
  background-position: -3em 0;
}

.AVA {
  background-position: -2em 0;
}

.AVL {
  background-position: -1em 0;
}

.AVP {
  background-position: 0 0;
}

.ACL {
  background-position: -4em 0;
}

.ACH {
  background-position: -5em 0;
}

.PRN {
  background-position: -6em 0;
}

.PRL {
  background-position: -7em 0;
}

.PRH {
  background-position: -8em 0;
}

.UIN {
  background-position: -10em 0;
}

.UIR {
  background-position: -9em 0;
}

.SC {
  background-position: -11em 0;
}

.SU {
  background-position: -10em 0;
}

.CH {
  background-position: -14em 0;
}

.CL {
  background-position: -13em 0;
}

.CN {
  background-position: -12em 0;
}

.IH {
  background-position: -16em 0;
}

.IL {
  background-position: -17em 0;
}

.IN {
  background-position: -15em 0;
}

.AH {
  background-position: -20em 0;
}

.AL {
  background-position: -19em 0;
}

.AN {
  background-position: -18em 0;
}

/* Normal state */
.ant-btn-primary {
  background-color: #0958d9;
  color: #fff;
}

/* Hover state */
.ant-btn-primary:not(:disabled):hover {
  background-color: #4096ff ;
  color: #fff;
}

/* Disabled state */
.ant-btn-primary:disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}

.icon-transition {
  transition: transform 0.3s ease-in-out;
}